import React from "react";
import {Alert, Col, Row} from "react-bootstrap";
import BookCard from "../Components/Book/BookCard";

function Books() {
    return (
        <div>
            <h1>Bücher</h1>
                <Alert variant="info">
                    <i className="fas fa-info-circle"></i> Hier werden alle Bücher aufgeführt, die ich bisher geschrieben habe.
                    Teilweise auch solche, die noch nicht veröffentlicht wurden oder aktuell aufgrund von Überarbeitung nicht erhältlich sind.
                    Also nicht wundern, wenn ihr keine Ausgabe von einem der Werke ergattern könnt.
                </Alert>
            <Row className="mb-5">
                <Col lg="6" md="12">
                    <BookCard
                        coverSource="bookCovers/cover_first_izenik.jpg"
                        bookTitle="Izenik"
                        bookSubTitle="Der erste Schritt"
                        bookShortDescription="Der erste Teil der Izenik-Trilogie. Die fünfzehnjährige Yannikasi ist ein ganz normales Mädchen. Dann lernt sie Lukas kennen und landet plötzlich in einer Welt voller Magie und Chaos."
                        bookPublished="09/2016"
                        buyMessage="Aktuell nicht erhältlich! Erste Auflage als print und e-Book über Amazon verkauft, zweite Auflage in Arbeit."
                        amazonLink=""
                    />
                </Col>
                <Col lg="6" md="12">
                    <BookCard
                        coverSource="bookCovers/cover_second_izenik.svg"
                        bookTitle="Izenik"
                        bookSubTitle="Weite Welten"
                        bookShortDescription="Der zweite Teil der Izenik-Trilogie. Hundert Jahre nachdem Yanni Izenik nach dem Krieg aufgebaut hatte, droht erneut großes Unheil. Wird sie der neuen Bedrohung gerecht werden?"
                        bookPublished="Bald"
                        buyMessage="Noch nicht erhältlich! Buch wurde bisher noch nicht veröffentlicht."
                        amazonLink=""
                    />
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg="6" md="12">
                    <BookCard
                        coverSource="bookCovers/cover_third_izenik.png"
                        bookTitle="Izenik"
                        bookSubTitle="Die Memoiren einer Erbin"
                        bookShortDescription="Der dritte Teil der Izenik-Trilogie. Solange der zweite Teil noch nicht veröffentlicht wird, erfolgt hier keine Beschreibung zwecks Spoiler :)"
                        bookPublished="Dauert noch ein wenig"
                        buyMessage="Noch nicht erhältlich! Buch wurde bisher noch nicht veröffentlicht."
                        amazonLink=""
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Books;