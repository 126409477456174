import React from "react";
import {Col, Row} from "react-bootstrap";
import NewsCard from "../Components/NewsCard/NewsCard";

function CurrentAffairs() {
        return (
            <div>
                <h1>Aktuelles</h1>
                <Row>
                        <Col lg="8" md="12">
                                <NewsCard
                                    date="07.02.2021"
                                    heading="Willkommen :)"
                                    content="Herzlich wilkommen auf meiner Website. Hier findet ihr Informationen zu meinen Büchern und allem, was darum herum wichtig ist, wie z. B. Veröffentlichungstermine. Viel Spaß."
                                />
                        </Col>
                </Row>
            </div>
        );
}

export default CurrentAffairs;