import {Card, Col, Row} from "react-bootstrap";
import React from "react";

function NewsCard(props) {
        return (
            <Card>
                <Card.Header>
                    <Row>
                        <Col lg="10" md="12">
                            <h5>{props.heading}</h5>
                        </Col>
                        <Col lg="2" md="12" className="text-right">
                            {props.date}
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        {props.content}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
}

export default NewsCard;