import React from "react";

function BookBuyDescription(props) {
    let buyingLink = null;
    if (props.amazonLink) {
        buyingLink = (
            <div className="border-top pt-3">
                <p>
                    <i className="fab fa-lg fa-amazon"></i>
                </p>
                <p>
                    <a href={props.amazonLink}>
                        Hier geht's zum Buch auf Amazon
                    </a>
                </p>
            </div>
        );
    }

    return (
        <div className="text-center">
            <i className="fas fa-2x fa-shopping-cart"></i>
            <p className="pt-3">{props.message}</p>
            {buyingLink}
        </div>
    );
}

export default BookBuyDescription;