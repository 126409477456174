import {Alert, Row, Col} from "react-bootstrap";

function NotFound() {
    return (
        <Row>
            <Col lg="7" md="12">
        <Alert variant="danger">
            <h2>
                Ups, da ist wohl was schiefgelaufen ...
            </h2>
            Die angeforderte Seite konnte leider nicht gefunden werden. Gültige Links finden sich in der Seitennavigation :)
        </Alert>
            </Col>
        </Row>
    );
}

export default NotFound;