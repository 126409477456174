import React from 'react';
import {Link} from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap';
import '../../App.css'
import './Header.css';

function Header ( props ) {
    let elementKey = 0;
    const headerLinks = props.headerLinks.map((element) => {
        elementKey++;

        return (
            <Nav.Item key={elementKey}>
                <Link className="nav-link" to={element.route}>{element.text}</Link>
            </Nav.Item>
        );
    });

    return (
        <Navbar className={props.className} bg="light" expand="lg">
            <Link className="navbar-brand" to="/">{props.brandText}</Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="w-100 border-0 justify-content-sm-around" variant="tabs">
                    {headerLinks}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;
