import React, {useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import './Book.css';

function Book (props) {
    const [openedBook, setOpenedBook] = useState(0);

    if (!openedBook) {
        return (
            <div className="justify-content-center mb-3" onClick={() => setOpenedBook(1)}>
                <Row>
                    <Col className="col-6 cursor-pointer">
                        <img className="img-fluid img-thumbnail" alt="Cover" src={props.coverSource}/>
                    </Col>
                </Row>
            </div>
        )
    } else {
        return (
            <div className="justify-content-center mb-3">
                <Row>
                    <Card className="col-6 p-1 border-double-left cursor-pointer" onClick={() => setOpenedBook(0)}>
                        <Row>
                            <Col className="text-center">
                                <h5>Titel</h5>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col className="text-center">
                                {props.title}
                                &nbsp;-&nbsp;
                                {props.subTitle}
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-center">
                                Erschienen:
                            </Col>
                            <Col className="text-center">
                                {props.published}
                            </Col>
                        </Row>
                    </Card>
                    <Card className="col-6 p-1 border-double-right">
                        <Row>
                            <Col className="text-center">
                                <h5>Inhalt</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                {props.shortDecription}
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </div>
        )
    }
}

export default Book;