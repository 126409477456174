import Header from './Components/Header/Header';
import CurrentAffairs from "./Actions/CurrentAffairs";
import Books from "./Actions/Books";
import Impressum from "./Actions/Impressum";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.min.css';
import './fontawesome/css/all.min.css';
import {Container} from "react-bootstrap";
import Appointments from "./Actions/Appointments";
import Contact from "./Actions/Contact";
import NotFound from "./Actions/NotFound";

function App() {
  const headerLinks = [
    {
      text: 'Aktuelles',
      route: '/currentAffairs'
    },
    {
      text: 'Bücher',
      route: '/books'
    },
    {
      text: 'Termine',
      route: '/appointments'
    },
    {
      text: 'Kontakt',
      route: '/contact'
    }
  ];

  return (
      <Router>
        <Header
            className="mb-2"
            brandText="CR - Autorin"
            headerLinks={headerLinks}
        />
        <Container fluid>
          <Switch>
            <Route path="/currentAffairs">
              <CurrentAffairs />
            </Route>
            <Route path="/books">
              <Books />
            </Route>
            <Route path="/appointments">
              <Appointments />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/impressum">
              <Impressum />
            </Route>
            <Route exact path="/">
              <CurrentAffairs />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Container>
        <footer className="bg-light border-top border-secondary mt-2">
          <div className="container p-2">
            <div className="row justify-content-center">
              <Link className="text-decoration-none" to="/impressum">Impressum & Datenschutz</Link>
            </div>
          </div>
        </footer>
      </Router>
  );
}

export default App;
