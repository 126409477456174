import React from "react";
import {Col, Row} from "react-bootstrap";
import ContactCard from "../Components/ContactCard/ContactCard";

function Contact() {
    return (
        <div>
            <h1>Kontakt</h1>
            <Row>
                <Col className="mb-3" lg="3" md="12">
                    <ContactCard
                        iconName="fas fa-at"
                        contactLink="mailto:info@crautorin.de"
                        contactString="info@crautorin.de"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" lg="3" md="12">
                    <ContactCard
                        iconName="fab fa-facebook-square"
                        contactLink="https://www.facebook.com/CRAutorin"
                        contactString="Christina Rauscher"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" lg="3" md="12">
                    <ContactCard
                        iconName="fab fa-instagram"
                        contactLink="https://www.instagram.com/crautorin"
                        contactString="CR Autorin"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mb-3" lg="3" md="12">
                    <ContactCard
                        iconName="fab fa-twitter"
                        contactLink="https://twitter.com/CRautorin"
                        contactString="CR Autorin"
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Contact;