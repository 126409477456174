import {Card, Col, Row} from "react-bootstrap";
import React from "react";

function ContactCard(props) {
    return (
        <Card className="p-2">
            <Row>
                <Col xs="2" className="border-right text-center">
                    <i className={props.iconName + " fa-lg align-middle"}></i>
                </Col>
                <Col xs="10" className="text-center">
                    <a className="text-decoration-none" href={props.contactLink} target="_blank" rel="noreferrer">{props.contactString}</a>
                </Col>
            </Row>
        </Card>
    );
}

export default ContactCard;