import {Card, Row} from "react-bootstrap";
import Book from "./Book";
import BookBuyDescription from "./BookBuyDescription";
import React from "react";

function BookCard(props) {
    return (
        <Card className="p-3">
            <Row>
                <div className="col-lg-8 col-md-12">
                    <Book
                        coverSource={props.coverSource}
                        title={props.bookTitle}
                        subTitle={props.bookSubTitle}
                        shortDecription={props.bookShortDescription}
                        published={props.bookPublished}
                    />
                </div>
                <div className="col-lg-4 col-md-12">
                    <BookBuyDescription
                        amazonLink={props.amazonLink}
                        message={props.buyMessage}
                    />
                </div>
            </Row>
        </Card>
    );
}

export default BookCard;