import React from "react";
import {Row, Col} from "react-bootstrap";
import NewsCard from "../Components/NewsCard/NewsCard";

function Appointments() {
    return (
        <div>
            <h1>Termine</h1>
            <Row>
                <Col lg="8" md="12">
                    <NewsCard
                        heading="Aktuell keine"
                        content="In Zukunft findet ihr hier alle wichtigen Termine, wie Veröffentlichungsdaten und Lesungen."
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Appointments;